import { writable } from 'svelte/store'
import type { Wallet } from '$lib/types'

/**
 * Custom wallet store creator
 */
export const createWallet = () => {
    const initialState: Wallet = {
        balanceAsMoney: { amount: 0, currency: null },
        country: null,
        newWallet: false,
    }

    const { subscribe, set } = writable({ ...initialState })

    return {
        subscribe,
        set,
        reset: () => set({ ...initialState }),
    }
}
