import { writable } from 'svelte/store'
import type { Allowance } from '$lib/types'
import SiruAxios from '../SiruAxios'

export const createAllowance = () => {
    const initialState: Allowance = {
        current: null,
        usage_in_30_days: null,
        tomorrow: null,
        dayAfterTomorrow: null,
        afterWeek: null,
        isBlocked: false,
        isBlacklisted: false,
        blockedUntil: null,
    }

    const { subscribe, set, update } = writable({ ...initialState })

    return {
        subscribe,
        set,
        reset: () => set({ ...initialState }),
        fetch: async () => {
            const { data } = await SiruAxios.get(`mysiru/rest/allowance`)

            return set({
                current: data.allowance.current,
                usage_in_30_days: data.usagein30days,
                tomorrow: data.allowance.tomorrow,
                dayAfterTomorrow: data.allowance.dayAfterTomorrow,
                afterWeek: data.allowance.afterWeek,
                isBlocked: data.isBlocked,
                isBlacklisted: false, // @TODO check is this present in data?
                blockedUntil: data.blockedUntil,
            })
        },
        checkBlock: async () => {
            const { data } = await SiruAxios.get(`mysiru/rest/checkblock`, {
                useGlobalErrorHandler: false,
            })

            return update(state => ({
                ...state,
                isBlocked: data.isBlocked,
                isBlacklisted: data.blacklisted,
            }))
        },
        setBlocked: async (duration: string, reason: null | string = null) => {
            const { data } = await SiruAxios.post(`mysiru/rest/block`, {
                duration,
                reason,
            })

            return update(state => ({
                ...state,
                isBlocked: true,
                blockedUntil: data.blockedUntil,
            }))
        },
    }
}
