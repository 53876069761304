import { writable } from 'svelte/store'
import { browser } from '$app/environment'
import { jwtDecode } from 'jwt-decode'
import SiruAxios from '$lib/SiruAxios'

/**
 * Custom authentication store creator
 */
export const createAuthentication = () => {
    let initialstate = { loggedIn: false, token: null }

    if (loggedIn()) {
        const token = getToken()
        initialstate = { loggedIn: true, token }
        SiruAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    const { subscribe, set } = writable(initialstate)

    return {
        subscribe,
        authenticate: token => {
            setToken(token)
            SiruAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            set({ loggedIn: true, token })
        },
        unAuthenticate: () => {
            clearToken()
            delete SiruAxios.defaults.headers.common['Authorization']
            set({ loggedIn: false, token: null })
        },
    }
}

// Utilities
/**
 * Checks if there is a saved token and it's still valid
 *
 * @returns {boolean}
 */
export const loggedIn = () => {
    const token = getToken()
    return !!token && !isTokenExpired(token)
}

/**
 * Check if given token is expired
 *
 * @param token
 * @returns {boolean}
 */
export const isTokenExpired = token => {
    try {
        const decoded = jwtDecode(token)
        return decoded.exp < Date.now() / 1000
    } catch (err) {
        return true
    }
}
/**
 * Set token to localStorage
 *
 * @param idToken
 */
const setToken = idToken => browser && localStorage.setItem('id_token', idToken)

/**
 * Get token from localstorage
 *
 * @returns {string}
 */
export const getToken = () => (browser ? localStorage.getItem('id_token') : null)

/**
 * Clear token from localstorage
 */
export const clearToken = () => browser && localStorage.removeItem('id_token')

/**
 * Get profile data from stored token
 *
 * @returns {*}
 */
export const getProfile = () => jwtDecode(getToken())
