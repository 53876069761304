import { sha256 } from 'js-sha256'
import { browser } from '$app/environment'

/**
 * Create an event to Google Analytics.
 *
 * @param action
 * @param parameters
 */
export const gaCreateEvent = (action, parameters) => {
    if (!browser || !window.gtag) return
    // Send an event
    window.gtag('event', action, parameters)
}

/**
 * Set's user id for Google Analytics.
 *
 * @param uid
 */
export const gaSetUserId = uid => {
    if (!browser || !window.gtag) return

    const hashed = sha256(String(uid))

    window.gtag('set', { user_id: hashed })
}
