import { writable } from 'svelte/store'
import type { DepositMethod } from '$lib/types'

export const createDepositables = () => {
    const initialState: DepositMethod[] = []

    const { subscribe, set } = writable([...initialState])

    return {
        subscribe,
        set,
        reset: () => set([...initialState]),
    }
}
