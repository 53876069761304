import axios from 'axios'
import { PUBLIC_API_URL } from '$env/static/public'

// Type our custom configuration options for axios
declare module 'axios' {
    export interface AxiosRequestConfig {
        useGlobalErrorHandler?: boolean
    }
}

const SiruAxios = axios.create({
    baseURL: PUBLIC_API_URL,
})

export default SiruAxios
