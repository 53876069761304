import { writable } from 'svelte/store'
import { load } from '@fingerprintjs/botd'
import type { BotDetectionResult } from '$lib/types'

export const createBotDetection = () => {
    const initialState: BotDetectionResult = {
        bot: null,
    }

    const { subscribe, update } = writable(initialState)

    return {
        subscribe,
        checkForBot: async () => {
            const botd = await load()
            const result = await botd.detect()

            return update(() => result)
        },
    }
}
