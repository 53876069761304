import { writable } from 'svelte/store'
import UAParser from 'ua-parser-js'
import SiruAxios from '$lib/SiruAxios'
import { Passkey } from '$lib/types'

export const createPasskeys = () => {
    const initialState: Passkey[] = []

    const { subscribe, set, update } = writable([...initialState])

    return {
        subscribe,
        set,
        fetch: async () => {
            const { data } = await SiruAxios.get<{ passkeys: Passkey[] }>(`mysiru/rest/passkeys`)
            const { passkeys } = data

            const parser = new UAParser()
            // Loop through each passkey and parse user agent to add device, os, and browser fields
            passkeys.forEach(passkey => {
                // Set the user agent to parse
                parser.setUA(passkey.userAgent)
                // Parse and extract device, OS, and browser details
                const parsedResult = parser.getResult()
                passkey.device = parsedResult.device.model || 'Unknown Device'
                passkey.os = parsedResult.os.name + ' ' + parsedResult.os.version || 'Unknown OS'
                passkey.browser = parsedResult.browser.name || 'Unknown Browser'
            })

            return set(passkeys)
        },
        delete: async (publicKeyCredentialId: string) => {
            await SiruAxios.delete(`mysiru/rest/passkeys/${publicKeyCredentialId}`)

            return update(passkeys => passkeys.filter(pk => pk.publicKeyCredentialId !== publicKeyCredentialId))
        },
        reset: () => set([...initialState]),
    }
}
