import { createFetching } from './fetching.js'
import { createAlerts } from './alerts.js'
import { createAuthentication } from './authentication.js'
import { createWallet } from './wallet'
import { createAllowance } from './allowance'
import { createBotDetection } from './botDetection'
import { createDepositables } from './depositables'
import { createFingerprint } from './fingerprint'
import { createHistory } from './history'
import { writable } from 'svelte/store'
import { createPasskeys } from './passkeys'

export const alerts = createAlerts()

export const allowance = createAllowance()

export const authentication = createAuthentication()

export const botDetection = createBotDetection()

export const depositables = createDepositables()

export const fetching = createFetching()

export const fingerprint = createFingerprint()

export const hasSuccessfulPurchase = writable(false)

export const history = createHistory()

export const passkeys = createPasskeys()

export const wallet = createWallet()
