import { writable } from 'svelte/store'
import SiruAxios from '../SiruAxios'
import type { HistoryItem } from '../types'

export const createHistory = () => {
    const initialState: HistoryItem[] = []

    const { subscribe, set } = writable([...initialState])

    return {
        subscribe,
        set,
        reset: () => set([...initialState]),
        fetch: async () => {
            const { data } = await SiruAxios.get(`mysiru/rest/history`)

            set(data.transactions)
        },
    }
}
