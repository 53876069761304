import { writable } from 'svelte/store'
import { gaCreateEvent } from '$lib/utilities/AnalyticsTools.js'

/**
 * Custom alerts store creator
 */
export const createAlerts = () => {
    const { subscribe, update, set } = writable([])

    return {
        subscribe,
        add: (severity, message) => {
            update(state => [...state, { severity, message }])
            gaCreateEvent('Display alert', {
                severity,
                message
            })
        },
        clear: i => update(state => state.filter((_, index) => index !== i)),
        clearAll: () => set([])
    }
}
