import { get, writable } from 'svelte/store'
import { fingerprint } from './index'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { log, captureMessage } from '$lib/utilities/errorTrackingTools'
import { PUBLIC_FPJS_TOKEN, PUBLIC_FPJS_URL, PUBLIC_FPJS_TARGET, PUBLIC_NETLIFY } from '$env/static/public'
import type { FingerprintResult } from '$lib/types'
import SiruAxios from '$lib/SiruAxios'

export const createFingerprint = () => {
    const initialState: FingerprintResult = null

    const { subscribe, set } = writable(initialState)

    return {
        subscribe,
        set,
        get: async () => {
            log({ message: 'Start loading the FpJS client instance (FP.load)', category: 'fingerprint' })

            // If 25 seconds after, we still do not have a fingerprint, capture error message for our trackers
            const fpFailureTimeout = setTimeout(() => {
                if (get(fingerprint) === null) {
                    captureMessage('Fingerprint failed on timeout')
                }
            }, 25_000)

            const fpClient = await FingerprintJS.load({
                apiKey: PUBLIC_FPJS_TOKEN,
                endpoint: PUBLIC_FPJS_URL,
                region: 'eu',
                scriptUrlPattern,
            })

            log({
                message:
                    'FpJS client instance has been loaded (fp.load finished). Starting the FpJS fingerprint creation.',
                category: 'fingerprint',
            })

            const requestMetadata = {
                requestType: 'mysiru',
                target: PUBLIC_FPJS_TARGET,
            }

            const response = await fpClient.get({ extendedResult: false, timeout: 20000, tag: requestMetadata })

            log({ message: 'FpJS Fingerprint creation has ended.', category: 'fingerprint', data: response })

            if (response.visitorId) {
                SiruAxios.defaults.headers.common['x-fpjs-request-id'] = response.requestId

                clearTimeout(fpFailureTimeout)

                return set(response)
            } else {
                throw new Error(`Error: Missing visitorId in response: ${JSON.stringify(response)}`)
            }
        },
    }
}

// Utilities
const scriptUrlPattern = [
    // FPjs script default endpoint
    FingerprintJS.defaultScriptUrlPattern,
]

if (PUBLIC_NETLIFY) {
    // In netlify, use primarily our proxied endpoint
    scriptUrlPattern.unshift('/fpjs/v<version>/<apiKey>/loader_v<loaderVersion>.js')
}
